@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Footer
*/
.p-footer {
  &__logo {
    width: 175px;
    margin-inline: auto;

    @include mq(lg) {
      width: 285px;
    }
    img {
      width: 100%;
    }
  }

  &__nav a {
    color: var(--l-primary-text-color);
    font-size: pxToRem(14px);

    @include mq(lg) {
      font-size: pxToRem(16px);
    }
  }

  &__copyright {
    background-color: var(--l-accent-color);
    line-height: 1.3;
    text-align: center;

    small {
      font-size: pxToRem(12px);
    }
  }
}
