@charset 'UTF-8';
@use "../setting" as *;
@use "../function" as *;
@use "sass:math";

//  pseudo
//--------------------------------------------
@mixin center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerT {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
