@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;
/*
  Global Nav
*/
.p-globalNav {
  visibility: hidden;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 135px 72px 80px;
  overflow-y: scroll;
  transition-property: opacity, box-shadow;
  transition-duration: 0.6s;
  // transform: translate3d(100%, 0, 0);
  opacity: 0;
  background: var(--l-base-color);
  // transition-timing-function: $easing;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  text-align: center;

  @include mq(lg) {
    width: 40%;
    text-align: left;
  }

  &__item {
    position: relative;
    transition: var(--l-duration);
    line-height: 1;

    @include mq(xl) {
      padding-left: 0;
    }
  }

  &__link {
    display: block;
    transition: var(--l-duration);
    font-size: pxToRem(14px);
    font-weight: 700;

    @include mq(xl) {
      color: var(--l-main-color);
      font-size: pxToRem(13px);
      text-align: center;
    }

    @media (min-width: 1300px) {
      font-size: pxToRem(14px);
    }
  }
}

.is-drawerActive .p-globalNav {
  visibility: visible;
  opacity: 1;
}

.is-drawerActive body {
  overflow: hidden;
}

.is-resize .p-globalNav {
  transition: none;
}
