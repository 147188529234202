@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
 button
-----------------------------------------------------*/

.c-button {
  display: block;
  width: 85%;
  max-width: 320px;
  margin: 0 auto;
  padding: 2.5em 0;
  overflow: hidden;
  transition: 0.4s;
  border: 1px solid var(--l-primary-text-color);
  font-size: pxToRem(14px);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  @include mq(lg) {
    max-width: 510px;
    font-size: pxToRem(16px);
  }

  &:hover {
    opacity: 1;
    background-color: #fff;
    color: var(--l-primary-text-color);
  }
}
