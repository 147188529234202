@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Service
*/

.p-service {
  &__item {
    position: relative;
    padding-left: 1.75em;
    font-size: pxToRem(16px);

    @include mq(lg) {
      font-size: min(1.6vw, 24px);
    }

    &::before {
      @include pseudo;
      content: "ー";
      top: 0;
      left: 0;
      font-size: 1em;
    }
  }
}
