@charset 'UTF-8';
@use "../setting" as *;
@use "../function" as *;
@use "sass:math";

//  pseudo
//--------------------------------------------
@mixin pseudo {
  content: "";
  position: absolute;
}

@mixin pseudoX {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@mixin pseudoY {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin pseudoXY {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
