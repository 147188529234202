@charset 'UTF-8';
@use "../setting" as *;
@use "sass:map";

//  Media Queries
//--------------------------------------------
@mixin mq($breakpoint, $type: min) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if ($type == max) {
      $width: map.get($breakpoints, $breakpoint);
      $width: $width - 1;
      @media screen and (max-width: #{$width}) {
        @content;
      }
    } @else if($type == min) {
      @media screen and (min-width: #{map.get($breakpoints, $breakpoint)}) {
        @content;
      }
    }
  }
}
