@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Company
*/

.p-company {
  font-size: pxToRem(14px);

  @include mq(lg) {
    font-size: pxToRem(16px);
  }

  &__item {
    padding: 1.25em 0;

    &:nth-child(2n + 1) {
      background: var(--l-light-gray-color);
    }
  }

  &__title {
    width: 35%;
    padding-left: 1em;

    @include mq(lg) {
      padding-left: 5em;
    }
  }

  &__text {
    width: 65%;
  }
}
