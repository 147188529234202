@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
 Hero
-----------------------------------------------------*/
.p-hero {
  @include center;
  width: 100%;
  height: 600px;

  @include mq(lg) {
    height: 100vh;
  }

  &__img {
    margin-inline: auto;
    max-width: 90%;
    animation: zoopUp 0.5s ease-in-out 0s 1 normal forwards;
  }
}

@keyframes zoopUp {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
