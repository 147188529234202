@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;
/*
  Hamburger
*/
.p-hamburger {
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  width: 140px;
  height: 100px;
  margin: auto;
  transition: transform 0.6 ease-in-out;
  border: 0;
  background-color: transparent;
  text-align: center;

  @include mq(lg) {
    width: 180px;
    height: 120px;
  }

  &__line {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 2px;
    margin: auto;
    transition: all 0.3s ease-in-out;
    background: var(--l-primary-text-color);

    @include mq(lg) {
      width: 110px;
      height: 2px;
    }
  }

  &__line:before,
  &__line:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: inherit;
    background: inherit;
  }

  &__line:before {
    right: 0;
    transform: translate3d(0, 14px, 0);
  }

  &__line:after {
    right: 0;
    transform: translate3d(0, -14px, 0);
  }

  .is-drawerActive & {
    &__line {
      background: transparent;

      &::before {
        transform: rotate(-20deg);
        background: var(--l-primary-text-color);
      }

      &::after {
        transform: rotate(20deg);
        background: var(--l-primary-text-color);
      }
    }
  }
}
