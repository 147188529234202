@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  About
*/

.p-about {
  .c-media__head {
    @include mq(lg) {
      flex-shrink: 0;
      width: math.div(328, 1187) * 100%;
    }
  }

  .c-media__body {
    // flex-grow: 1;
    @include mq(lg) {
      width: math.div(728, 1187) * 100%;
    }

    p {
      font-size: pxToRem(16px);

      @include mq(lg) {
        font-size: min(1.6vw, 24px);
        line-height: 2;
      }
    }
  }
}
