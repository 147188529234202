@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
 Utility
-----------------------------------------------------*/
.u-br_sp {
  display: block;
  @include mq(md) {
    display: inline;
  }
}

.u-b {
  display: block;
}

.u-ib {
  display: inline-block;
}

.u-none {
  display: none;
}

.u-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.u-pc {
  display: none;

  @include mq(lg) {
    display: block;
  }
}

.u-sp {
  display: block;
  @include mq(lg) {
    display: none;
  }
}

.u-cMain {
  color: var(--l-main-color);
}

.u-tRight {
  text-align: right;
}
.u-tCenter {
  text-align: center;
}
