@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Results
*/

.p-results {
  &__img {
    border: 1px solid #707070;
  }

  &__title {
    font-weight: 700;
  }
}
