@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Policy
*/

.p-policy {
  &_title {
    font-size: pxToRem(18px);

    @include mq(lg) {
      font-size: 20px;
    }
  }

  &__text {
    font-size: pxToRem(18px);
    font-weight: 700;

    @include mq(lg) {
      font-size: 20px;
    }
  }
}
