@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
 heading
-----------------------------------------------------*/
.c-heading {
  //トップページ

  .-en {
    font-family: var(--l-en-font-set);
    font-size: pxToRem(36px);

    letter-spacing: 0.05em;
    line-height: 1.2;
    text-transform: uppercase;

    @include mq(lg) {
      font-size: pxToRem(78px);
    }
  }

  .-ja {
    display: block;
    font-family: var(--l-primary-font-set);
    font-size: pxToRem(12px);
    font-weight: 700;
    letter-spacing: 0.08em;
    line-height: 1;
    @include mq(lg) {
      font-size: pxToRem(16px);
    }
  }
}
