@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Utility
-----------------------------------------------------*/
.u-visuallyHidden:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
  white-space: nowrap;
  clip-path: inset(50%);
}
