@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Container
-----------------------------------------------------*/
.#{$prefix}-container {
  &:not([data-width]) {
    width: 90%;
  }
  &:not([data-max]) {
    max-width: calc(var(--#{$prefix}-lg-content-width) * 1px);
  }
  &:not([data-align]) {
    margin: 0 auto;
  }

  @each $device, $breakpoint in $breakpoints {
    @media screen and (min-width: $breakpoint) {
      &[data-width~="#{$device}:xsmall"] {
        width: 70%;
      }
      &[data-width~="#{$device}:small"] {
        width: 80%;
      }
      &[data-width~="#{$device}:medium"] {
        width: 90%;
      }
      &[data-width~="#{$device}:large"] {
        width: 95%;
      }
      &[data-width~="#{$device}:auto"] {
        width: auto;
      }

      &[data-max~="#{$device}:small"] {
        max-width: calc(var(--#{$prefix}-sm-content-width) * 1px);
      }
      &[data-max~="#{$device}:medium"] {
        max-width: calc(var(--#{$prefix}-md-content-width) * 1px);
      }
      &[data-max~="#{$device}:960"] {
        max-width: calc(var(--#{$prefix}-md960-content-width) * 1px);
      }
      &[data-max~="#{$device}:large"] {
        max-width: calc(var(--#{$prefix}-lg-content-width) * 1px);
      }
      &[data-max~="#{$device}:xlarge"] {
        max-width: calc(var(--#{$prefix}-xl-content-width) * 1px);
      }
      &[data-max~="#{$device}:xxlarge"] {
        max-width: calc(var(--#{$prefix}-xxl-content-width) * 1px);
      }
      &[data-max~="#{$device}:none"] {
        max-width: none;
      }

      &[data-align~="#{$device}:left"] {
        margin-right: auto;
        margin-left: 0;
      }
      &[data-align~="#{$device}:right"] {
        margin-right: 0;
        margin-left: auto;
      }
      &[data-align~="#{$device}:center"] {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
