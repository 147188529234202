@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
  Header
*/

.p-header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100px;
  transition: all 0.3s ease-in-out;

  &__title {
    width: 120px;

    @include mq(lg) {
      width: 170px;
    }

    img {
      width: 100%;
    }
  }

  .is-drawerActive &::before {
    @include pseudo;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }
}

// &__overlay {
//   visibility: hidden;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   transition: opacity 1s;
//   opacity: 0;
//   background-color: rgba(0, 0, 0, 0.3);
//   cursor: pointer;
// }
