@charset 'UTF-8';
@use "sass:math";
@use "../global" as *;

/*
 media text
-----------------------------------------------------*/
.c-media {
  &__lead {
    font-family: var(--l-serif-font-set);
    font-size: pxToRem(20px);
    @include mq(lg) {
      font-size: min(2.2vw, pxToRem(26px));
    }
  }

  &__text {
    p {
      line-height: 1.875;
    }

    p + p {
      margin-top: 1em;
    }
  }

  &__img {
    flex-grow: 1;
  }
}
